import _ from 'lodash'
import {withActions} from 'carmi-host-extensions/src/aspects/withActions'
import {INTENTS, NULL_RETURN_VALUE} from '../constants'
import {tpaHandlersFunctionLibrary} from '../tpaHandlers/tpaHandlers'
import {pubSubFunctionLibrary, PUB_SUB_ASPECT_PREFIX} from '../tpaPubSub/tpaPubSub'
import {tpaPageNavigationFunctionLibrary} from '../tpaPageNavigation/tpaPageNavigation'
import {popupFunctionLibrary} from '../tpaPopup/tpaPopup'
import {modalFunctionLibrary} from '../tpaModal/tpaModal'
import {tpaStyleServicesFunctionLibrary} from '../services/tpaStyleServices'
import {reportHandlerCalledBi} from 'bolt-tpa/src/bi/tpaBi'
import tpaComponents from 'tpaComponents'
import {urlUtils, throttleUtils} from 'coreUtils'
import {FrameStorageListener} from 'data-capsule'

export {
    name
} from './tpaPostMessageAspect.schema'

export const defaultModel = {
    events: {},
    biEventsMap: {},
    [PUB_SUB_ASPECT_PREFIX]: {
        hub: {},
        messageHandled: {}
    },
    pageNavigation: {
        listeners: {},
        currentPageId: ''
    },
    popups: {},
    modals: {}
}

const UNIQUE_ID_PRFIX = 'tpa-mgs-'
const THROTTLE_CHUNK_SIZE = 1
const THROTTLE_CHUNK_INTERVAL = 100

const callPostMessage = (source, data, targetOrigin) => {
    let msgStr = ''
    try {
        msgStr = JSON.stringify(data)
    } catch (e) {
        return
    }

    if (!source.postMessage) {
        source = source.contentWindow
    }

    source.postMessage(msgStr, targetOrigin || '*')
}

const parseMessage = event => {
    let msg
    try {
        if (event.data) {
            msg = JSON.parse(event.data)
        } else if (event.originalEvent && event.originalEvent.data) {
            event = event.originalEvent
            msg = JSON.parse(event.data)
        }
    } catch (e) {
        //skip non TPA messages
    }
    return msg
}

const isTPAMessage = msgIntent => msgIntent === INTENTS.TPA_MESSAGE || msgIntent === INTENTS.TPA_MESSAGE2

const isValidWixDomain = (origin, baseDomain = '') => {
    let wixBase = _.clone(baseDomain)
    wixBase = wixBase.replace(/\./ig, '\\.')
    const regex = new RegExp(`[\.\/]{1}${wixBase}$`, 'ig')
    return regex.test(origin)
}


const verifier = (tpaReactComps, source, origin, token) => !!tpaReactComps[token]

const interceptor = (tpaReactComps, metaSiteId, options, source, origin, token) => {
    options.namespace = _.get(tpaReactComps[token].getAppData(), 'appDefinitionId')
    options.scope = metaSiteId
    return options
}


export const functionLibrary = _.assign(
    tpaHandlersFunctionLibrary,
    tpaStyleServicesFunctionLibrary,
    pubSubFunctionLibrary,
    tpaPageNavigationFunctionLibrary,
    popupFunctionLibrary,
    modalFunctionLibrary,
    {
        isValidWixDomain,
        parseTPAMessage: withActions((aspectActions, event) => {
            const msg = parseMessage(event)
            if (msg && isTPAMessage(msg.intent)) {
                msg.source = event.originalEvent ? event.originalEvent.source : event.source
                msg.origin = event.originalEvent ? event.originalEvent.origin : event.origin
                return msg
            }
            return null
        }),
        response: withActions((aspectActions, event, eventId, result, biData) => {
            const {callId, type, source} = event
            const sendPostMessageWithStatus = status => finalResult => {
                finalResult = finalResult === NULL_RETURN_VALUE ? null : finalResult
                try {
                    callPostMessage(source, {
                        intent: INTENTS.TPA_RESPONSE,
                        callId,
                        type,
                        res: finalResult,
                        status
                    })
                } catch (e) {
                    console.error(e)
                }
                // need this here in case waitForPromiseToBeResolved was defined
                aspectActions.setMessage(eventId, undefined)
            }

            reportHandlerCalledBi(aspectActions, event, biData)

            if (_.isNil(result) ||
                result && !result.waitForPromiseToBeResolved) {
                // Setting the message with undefined will delete the key from the events object
                aspectActions.setMessage(eventId, undefined)
            }

            if (!_.isNil(result)) {
                result = result.waitForPromiseToBeResolved ? result.promise : result
                Promise.resolve(result).then(sendPostMessageWithStatus(true), sendPostMessageWithStatus(false))
            }
        }),
        sendPostMessage: (comp, data) => {
            const iframe = comp.getIframe()
            if (iframe) {
                callPostMessage(iframe, data)
            }
        },
        noHandlerFound: handlerType => {
            throw new Error(`Handler of type [${handlerType}] does not exist`)
        },
        getPixelUrl: ({pixelUrl, instance}, currentPageUrl) => new tpaComponents.common.TPAUrlBuilder(pixelUrl)
            .addMultipleQueryParams({
                instance,
                page: currentPageUrl,
                ck: urlUtils.cacheKiller()
            }).build(),
        addPixelTrackers: (urls, masterPageComp) => {
            if (masterPageComp) {
                throttleUtils.throttledForEach(
                    urls,
                    url => {(new window.Image(0, 0)).src = url},
                    THROTTLE_CHUNK_SIZE,
                    THROTTLE_CHUNK_INTERVAL
                )
            }
        },
        initDataCapsule: (tpaReactComps, metaSiteId, windowObj) => {
            if (!_.isEmpty(tpaReactComps) && windowObj) {
                new FrameStorageListener().start(_.partial(verifier, tpaReactComps), _.partial(interceptor, tpaReactComps, metaSiteId))
            }
        }
    })

const shouldHandleMessage = (msg, isPreview) => {
    const dsTpaHandlrers = {
        smCurrentMember: true,
        refreshCurrentMember: true,
        getValue: true,
        getValues: true
    }

    return msg && (!isPreview || !dsTpaHandlrers[msg.type])
}

function handlePostMessage(aspectActions, {parseTPAMessage, isPreview}, event) {
    const message = parseTPAMessage(event)
    if (shouldHandleMessage(message, isPreview)) {
        message.id = _.uniqueId(UNIQUE_ID_PRFIX)
        aspectActions.setMessage(message.id, message)
    }
}

export function init(aspectActions, {eventsManager, initialData}) {
    eventsManager.on('windowMessage', event => {
        handlePostMessage(aspectActions, initialData, event)
    })
}

