import _ from 'lodash'
import {utils} from 'santa-renderer'
import {exetendWithQaInfo} from './components/qaHOC' // TODO: rename file
import EventEmitter from 'events'

const {hostRuntimeUtils} = utils

const getRuntimeInit = BoltRuntimes => {
    const boltInit = (modelFactory, model, batchingStrategy, renderingFunctionsLib, hostApi, logger, functionLibraryOverrides, exceptionHandlingApi) => {
        const eventsManager = new EventEmitter()
        const boltFunctionLib = hostRuntimeUtils.buildFuncLib(eventsManager, BoltRuntimes, {}, renderingFunctionsLib, hostApi, functionLibraryOverrides)

        const boltDefaultModel = hostRuntimeUtils.buildDefaultModel(BoltRuntimes)

        const shouldExtendWithQAInfo = typeof window !== 'undefined' && _.get(model, ['currentUrl', 'query', 'isqa']) === 'true'
        const {compRegistrar} = functionLibraryOverrides.requireSync('componentsCore')

        const wrapper = (BaseClass, componentType) =>
            _.flow([
                boltFunctionLib.wrapper,
                comp => shouldExtendWithQAInfo ? exetendWithQaInfo(comp, componentType) : comp
            ])(BaseClass, componentType)
            
        if (shouldExtendWithQAInfo) {
            compRegistrar.registerCommonExtensionForAllComponents(wrapper)
        }

        const instance = modelFactory(_.merge({}, boltDefaultModel, model), {...boltFunctionLib, wrapper}, batchingStrategy)

        return hostRuntimeUtils.initInstance(instance, eventsManager, BoltRuntimes, logger, exceptionHandlingApi)
    }

    const runtimeModulesForValidation = [{functionLibrary: hostRuntimeUtils.buildFuncLib(new EventEmitter())}, ...BoltRuntimes]

    return {
        boltInit,

        // TODO remove when we have namespaces
        runtimeModulesForValidation
    }
}

export {
    getRuntimeInit
}
