import _ from 'lodash'
import {withActions} from 'carmi-host-extensions/src/aspects/withActions'
import {getBundledPartsDataRequest} from './dataRequirementChecker/builderDataHandler'
import {objectUtils} from 'santa-core-utils'
import {getPartViews} from '../../utils/listBuilder/appRepo'

export const name = 'ListBuilderAspect'

export const defaultModel = {
    requestFailed: false,
    warmup: {},
    metadata: {
        items: {}
    }
}

const markItemsState = (items, state, setAppPartItemRequestsMetadata) => {
    _.forEach(items, appPartName => {
        setAppPartItemRequestsMetadata(appPartName, state)
    })
}

const onAppPartFetchSuccess = ({$runInBatch, setRequestFailed, setAppPartItemRequestsMetadata, requestDescriptor, getWixAppsStore, setWixAppsPackage}, response) => {
    const partialWixappsStore = {
        wixapps: {
            appbuilder: objectUtils.cloneDeep(getWixAppsStore().appbuilder)
        }
    }
    const requestAppPartIds = requestDescriptor.data.appPartIds

    if (!response) {
        $runInBatch(() => {
            markItemsState(requestDescriptor.data.appPartIds, 'error', setAppPartItemRequestsMetadata)
            setRequestFailed(true)
        })
        return
    }

    const currentValue = _.get(partialWixappsStore, requestDescriptor.destination, {})
    const value = requestDescriptor.transformFunc(response, currentValue)
    $runInBatch(() => {
        _.set(partialWixappsStore, requestDescriptor.destination, value)
        setWixAppsPackage('appbuilder', partialWixappsStore.wixapps.appbuilder)

        markItemsState(requestAppPartIds, 'loaded', setAppPartItemRequestsMetadata)
    })
}

const onAppPartFetchError = ($runInBatch, setRequestFailed, setAppPartItemRequestsMetadata, appPartIds) => {
    $runInBatch(() => {
        markItemsState(appPartIds, 'error', setAppPartItemRequestsMetadata)
        setRequestFailed(true)
    })
}

export const functionLibrary = {
    fetchListBuilderData: withActions(({$runInBatch, setRequestFailed, setAppPartItemRequestsMetadata}, appPartsToFetch, fetchParams) => {
        if (_.isEmpty(appPartsToFetch)) {
            return
        }

        const {getListBuilderDrcAPI, appService, getWixAppsStore, setWixAppsPackage, fetchFn} = fetchParams
        const requestDescriptor = getBundledPartsDataRequest(getListBuilderDrcAPI(), appService, appPartsToFetch)
        const fetchOptions = {
            method: 'POST',
            body: JSON.stringify(requestDescriptor.data),
            headers: {
                'Content-Type': 'application/json'
            }
        }

        fetchFn(
            requestDescriptor.url,
            fetchOptions,
            'json',
            response => onAppPartFetchSuccess({
                $runInBatch,
                setRequestFailed,
                setAppPartItemRequestsMetadata,
                requestDescriptor,
                getWixAppsStore,
                setWixAppsPackage
            }, response),
            () => onAppPartFetchError($runInBatch, setRequestFailed, setAppPartItemRequestsMetadata, requestDescriptor.data.appPartIds)
        )
    }),
    getDataSelectorForUnsupportedType: (logicalTypeName, captureError) => {
        captureError(new Error(`Invalid selector type - ${logicalTypeName}`))
        return null
    },
    getPartViews
}
