import _ from 'lodash'
import {mapLanguageCodeToName} from 'santa-multilingual/dist/languages'

const defaultModel = {}
const name = 'multilingual'
const functionLibrary = {
    mapLanguageCodeToName,
    handleLanguageChange: (languageCode, currentNavigationInfo, navigateToPageFn) => {
        const newNavigationInfo = _.cloneDeep(currentNavigationInfo)
        _.set(newNavigationInfo, 'queryParams.lang', languageCode)
        _.unset(newNavigationInfo, 'anchorData')
        navigateToPageFn(newNavigationInfo)
    },
    handleLanguageChangeFromMenu: (navigateToLangFn, allLoadedLanguages, originalLanguageCode, languageCode) => {
        const resolutionMethod = extractResolutionMethod(allLoadedLanguages, languageCode)
        navigateToLangFn(languageCode, resolutionMethod, originalLanguageCode)
    }
}

function extractResolutionMethod(languagesList, languageCode) {
    const currentLanguage = languagesList.find(lang => lang.languageCode === languageCode)
    return currentLanguage.resolutionMethod
}

export {name, functionLibrary, defaultModel}
