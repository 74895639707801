import {withActions} from 'carmi-host-extensions/src/aspects/withActions'
import {reportError, reportEvent} from '../../utils/wixappsLogger'
import {getDataByPath, setDataByPath, getExtraDataByCompId} from '../../utils/wixappsDataHandler'
import {todoFunctionLibrary} from '../todoAspect/todoAspect'
import {colorParser} from 'carmi-host-extensions/src/aspects/utils/colors'
import {santaTypesDefinitions} from 'santa-components'
import _ from 'lodash'
import FunctionLibrary from '../../utils/functionLibrary'
import {getAppPageParams} from '../../utils/wixappsUrlParser'

export const name = 'WixappsCoreAspect'

export const defaultModel = {
    shouldReRenderSinglePostComp: false,
    compsInfoToRender: {},
    wixapps: {
        appbuilder: {},
        faq: {},
        news: {},
        menu: {},
        blog: {}
    }
}

const addCustomProps = (siteData = {}, propTypes, compStructure) => {
    let propsToAdd = {}
    const setCompDataKey = _.findKey(propTypes, {id: santaTypesDefinitions.DAL.setCompData.id})

    if (setCompDataKey) {
        propsToAdd = _.assign({}, propsToAdd, {[setCompDataKey]: _.noop})
    }

    if (compStructure.componentType === 'wysiwyg.viewer.components.MediaRichText') {
        propsToAdd = _.assign({}, propsToAdd, {siteData})
    }

    return propsToAdd
}

export const functionLibrary = {
    getComponentProps: withActions(({setCompInfoToRender, compProps, compClasses, $runInBatch}, siteData, compStructure, rootId, propsOverride) => {
        rootId = _.isNil(rootId) ? 'stamNonExistingId' : rootId
        $runInBatch(() => setCompInfoToRender(compStructure.id, {structure: {...compStructure, parent: rootId, metaData: {noStyle: true}}, props: propsOverride}))
        const componentProps = compProps[compStructure.id]
        const customProps = addCustomProps(siteData, compClasses[compStructure.componentType].propTypes, compStructure)

        return _.assign({}, componentProps, customProps)
    }),
    parseAppPage: (appPageId, urlExtraData) => getAppPageParams(appPageId, urlExtraData),
    getExtraDataByCompId: (wixappsStore, pkgName, compId) => getExtraDataByCompId(wixappsStore[pkgName], compId),
    getAppPageUrl: (renderInfo, requireFn, pageId, itemId, itemTitle) => {
        const warmupUtils = requireFn('warmupUtils')
        const pageInfo = {
            pageId,
            title: itemTitle,
            pageAdditionalData: itemId
        }

        return warmupUtils.wixUrlParser.getUrl(renderInfo, pageInfo)
    },
    getLogic: (appPartName, requireFn) => requireFn('wixappsCore').logicFactory.getLogicClass(appPartName),
    wixAppsReportEvent: reportEvent,
    wixAppsReportError: reportError,
    wixAppsGetMeasures: (measureMap, id) => _.transform(measureMap, (acc, value, key) => {
        if (value[id]) {
            acc[key] = value[id]
        }
    }, {}),
    setPageTitle: ({setCompData, getCurrentPageId}, title, descriptionSEO) => {
        setCompData(getCurrentPageId(), {
            title,
            descriptionSEO
        })
    },
    wixAppsGetDataByPath: (wixapps, packageName, path) => getDataByPath(wixapps[packageName], path),
    wixAppsSetDataByPath: (getWixapps, setWixapps, packageName, path, value) => setDataByPath(getWixapps(), setWixapps, packageName, path, value),
    getColorWixApps: (colorsMap, colorId) => colorParser.getColor(colorId, colorsMap),
    getWixappsFunctionLibrary: (isMobile, zoom, serviceTopology, getColor, isExperimentOpen, baseMediaUrl, blogTranslations) =>
        FunctionLibrary.bind(null, isMobile, zoom, serviceTopology, getColor, isExperimentOpen, baseMediaUrl, blogTranslations),
    ...todoFunctionLibrary
}
