import _ from 'lodash'

const name = 'AccessibilityAspect'
const defaultModel = {}
const functionLibrary = {
    createAriaLabelTranslationFunc: allTranslationsForLanguage =>
        (feature, key, defaultValue) => _.get(allTranslationsForLanguage, [feature, key], defaultValue)
}

export {name, functionLibrary, defaultModel}
