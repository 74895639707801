import _ from 'lodash'

export const name = 'VisualFocusAspect'
let $ // eslint-disable-line santa/no-module-state

let elementWithFocusRing = null // eslint-disable-line santa/no-module-state

const focusRingClass = 'focus-ring'

const KEY_CODES = {
    ESC: 27,
    SPACE: 32,
    TAB: 9,
    END: 35,
    HOME: 36,
    RIGHT_ARROW: 39,
    LEFT_ARROW: 37,
    UP_ARROW: 38,
    DOWN_ARROW: 40
}

const addFocusRingClass = el => {
    $(el).addClass(focusRingClass)
}

const removeFocusRingClass = el => {
    $(el).removeClass(focusRingClass)
}

const handlers = {
    documentKeyUp: e => {
        if (e.altKey || e.ctrlKey || e.metaKey) {
            return
        }
        if (_.includes([
            KEY_CODES.ESC,
            KEY_CODES.SPACE,
            KEY_CODES.TAB,
            KEY_CODES.HOME,
            KEY_CODES.END,
            KEY_CODES.RIGHT_ARROW,
            KEY_CODES.LEFT_ARROW,
            KEY_CODES.UP_ARROW,
            KEY_CODES.DOWN_ARROW
        ], e.keyCode)) {
            addFocusRingClass(e.target)
        }
    },
    windowFocus: () => {
        if (window.document.activeElement === elementWithFocusRing) {
            addFocusRingClass(elementWithFocusRing)
        }
    },
    windowBlur: () => {
        if ($(window.document.activeElement).hasClass(focusRingClass)) {
            elementWithFocusRing = window.document.activeElement
        }
    },
    elementBlur: e => {
        if ($(e.target).hasClass(focusRingClass)) {
            elementWithFocusRing = e.target
            removeFocusRingClass(e.target)
        }
    },
    elementFocus: () => {
        if (window.document.activeElement === elementWithFocusRing) {
            addFocusRingClass(elementWithFocusRing)
        }
    }
}

export const functionLibrary = {
    handleEventIfVisualEnabled: (isVisualFocusEnabled, eventType, event) => {
        if (!$) {
            $ = require('zepto')
        }
        if (!isVisualFocusEnabled) {
            return
        }
        const handle = handlers[eventType]
        handle(event)
    }
}

export function init({}, {eventsManager, initialData: {handleEventIfVisualEnabled}}) {
    eventsManager.on('documentKeyUp', e => handleEventIfVisualEnabled('documentKeyUp', e))
    eventsManager.on('documentBlur', e => handleEventIfVisualEnabled('elementBlur', e))
    eventsManager.on('documentFocus', e => handleEventIfVisualEnabled('elementFocus', e))
    eventsManager.on('windowFocus', e => handleEventIfVisualEnabled('windowFocus', e))
    eventsManager.on('windowBlur', e => handleEventIfVisualEnabled('windowBlur', e))
}
