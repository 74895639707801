import _ from 'lodash'
import {withActions} from 'carmi-host-extensions'
import {getRunUserFunctionMessage, getNativeTpaEventMessage} from './messageBuilder'
import {getSMbySiteExtensionInstanceForRgi} from '../../../init/functionLibrary/misc'
import parseCookie from '../../../init/functionLibrary/parseCookie'
import {toQueryParams} from '../../../init/platform/platformFunctionLibrary1'
import {sendStartMessage} from './startMessage'
import {sendInitBIEvent} from '../../../bi/platformBI'
export {dispatchName as name} from '../platform.schema'

export const defaultModel = {
    didStart: {}
}

const pendingMessagesMap = new WeakMap()

function getEvent(event, actionName) {
    const result = _.merge(_.pick(event, 'item'), _.pickBy(event, _.negate(_.isObject)))
    result.nativeEvent = _.pickBy(event.nativeEvent, _.negate(_.isObject))
    if (actionName) {
        result.action = actionName
    }
    if (event.data) {
        result.data = event.data
    }
    return result
}

const sendInitMessage = (wixCodeAppAPI, contextPageId, apps, wixBiSession, contextModel) => {
    const message = {
        type: 'wix_code_worker_init',
        id: contextPageId,
        apps
    }
    wixCodeAppAPI.sendMessage(message)
    sendInitBIEvent(wixBiSession, apps, contextPageId, contextModel)
}

const sendSpecificUpdateMessage = (getWixCodeAppAPI, getContextIds, getDidStart, isAfterClientSpecMapReload, messages) => {
    const didStart = getDidStart()
    _.forEach(getContextIds(), contextId => {
        const wixCodeAppAPI = getWixCodeAppAPI()
        if (wixCodeAppAPI[contextId] && didStart[contextId] || isAfterClientSpecMapReload) {
            _.forEach(messages, message => wixCodeAppAPI[contextId].sendMessage(_.assign(message, {contextId})))
        }

        return null
    })
}

const createBatchedUpdateMessage = (messages, pageId) => {
    const batchedUpdates = _.reduce(messages, (acc, {fieldName, compId, value}) =>
        _.set(acc, [compId, fieldName], value),
    {})

    return {
        contextId: pageId,
        intent: 'WIX_CODE',
        type: 'update',
        updates: batchedUpdates
    }
}

const initPendingMessages = (contextId, instance) => {
    if (!pendingMessagesMap.has(instance)) {
        pendingMessagesMap.set(instance, {scheduledFlush: {p: null}, pendingMessages: {}})
    }
    const {pendingMessages, scheduledFlush} = pendingMessagesMap.get(instance)
    if (!pendingMessages[contextId]) {
        pendingMessages[contextId] = {runCode: [], updates: []}
    }
    return {pendingMessages, scheduledFlush}
}

const addMessageToPending = (message, contextId, queueName, flush, instance) => {
    const {pendingMessages, scheduledFlush} = initPendingMessages(contextId, instance)
    pendingMessages[contextId][queueName].push(message)
    if (!scheduledFlush.p) {
        scheduledFlush.p = Promise.resolve().then(flush)
    }
}

export const functionLibrary = {
    startPlatformWorker: (contextPageId, getWixCodeAppAPI, contextModel, apps, didStart, setDidStart, deletedOldIsStarted, wixBiSession) => {
        if (didStart) {
            return contextModel
        }
        const wixCodeAppAPI = getWixCodeAppAPI()
        sendInitMessage(wixCodeAppAPI, contextPageId, apps, wixBiSession, contextModel)
        sendStartMessage(wixCodeAppAPI, contextPageId, contextModel)
        setDidStart(true)
        return contextModel
    },

    deleteIsStarted: withActions((actions, contextId) => {
        actions.setDidStart(contextId, undefined)
    }),

    pushMessageIfNeeded: withActions((instance, message, contextId, getContextState, flush) => {
        const shouldSendMessage = () => {
            const contextState = getContextState()
            const isWorkerStarted = contextState.isWorkerStarted
            const canStartListening = contextState.canStartListening
            const allowDataUpdates = canStartListening && isWorkerStarted && message.fieldName === 'state'
            const shouldPushMessage = !contextState.isHandlingMessage || allowDataUpdates
            return shouldPushMessage && isWorkerStarted && canStartListening
        }

        if (shouldSendMessage()) {
            addMessageToPending(message, contextId, 'updates', flush, instance)
        }
    }),

    pushRunCodeMessage: withActions((instance, behaviour, contextId, flush) => {
        addMessageToPending(behaviour, contextId, 'runCode', flush, instance)
    }),

    flushPendingPlatformMessages: withActions((instance, wixCodeAppAPI, contextIdsToPages) => {
        const {pendingMessages} = pendingMessagesMap.get(instance)

        _.forOwn(pendingMessages, ({updates, runCode}, contextId) => {
            if (!wixCodeAppAPI[contextId]) { //cause it's async the worker might have died
                return
            }
            const pageId = contextIdsToPages[contextId]
            if (updates.length > 0) {
                const updateMessage = createBatchedUpdateMessage(updates, pageId)
                wixCodeAppAPI[contextId].sendMessage(updateMessage)
            }

            _.forEach(runCode, behavior => {
                const actionName = _.get(behavior, ['action', 'name'])
                const message = getRunUserFunctionMessage(pageId, behavior.params, behavior.compId, getEvent(behavior.event, actionName))
                wixCodeAppAPI[contextId].sendMessage(message)
            })
        })

        pendingMessagesMap.set(instance, {scheduledFlush: {p: null}, pendingMessages: {}})
    }),

    sendClientSpecMapUpdates: withActions((actions, appsDataForRgi, {getWixCodeAppAPI, getContextIds, getDidStart, getElementoryArguments, isAfterClientSpecMapReload}) => {
        if (appsDataForRgi) {
            const updateAppsDataMsg = {
                intent: 'WIX_CODE',
                type: 'update_apps_data',
                updates: appsDataForRgi
            }

            const siteMembersData = getSMbySiteExtensionInstanceForRgi(appsDataForRgi) || {}
            const updateSiteMemberMsg = {
                intent: 'WIX_CODE',
                type: 'update_site_member',
                updates: {
                    uid: siteMembersData.uid,
                    permissions: siteMembersData.permissions
                }
            }

            const elementoryArguments = getElementoryArguments()
            const updateWixCodeModeDataAfterLoginMsg = {
                type: 'update_wix_code_model_data_after_login',
                elementoryArguments
            }

            const messages = [
                updateWixCodeModeDataAfterLoginMsg,
                updateAppsDataMsg
            ]

            if (isAfterClientSpecMapReload) {
                messages.push(updateSiteMemberMsg)
            }

            sendSpecificUpdateMessage(getWixCodeAppAPI, getContextIds, getDidStart, isAfterClientSpecMapReload, messages)
        }
    }),
    sendSvSessionUpdates: withActions((actions, svSession, {getWixCodeAppAPI, getContextIds, getDidStart}) => {
        if (svSession) {
            const message = {
                intent: 'WIX_CODE',
                type: 'update_session_info',
                updates: {svSession}
            }
            sendSpecificUpdateMessage(getWixCodeAppAPI, getContextIds, getDidStart, false, [message])
        }
    }),

    sendRgiUpdateOnNavigation: (navigation, {getWixCodeAppAPI, getContextIds, getDidStart}, isTpaInnerRouteNavigation) => {
        if (navigation && isTpaInnerRouteNavigation) {
            const message = {
                intent: 'WIX_CODE',
                type: 'update_navigation',
                updates: navigation
            }
            sendSpecificUpdateMessage(getWixCodeAppAPI, getContextIds, getDidStart, false, [message])
        }
    },

    //I'm guessing that what we get here is the contextPageId
    sendNativeTpaEventMessage: (wixCodeAppAPI, isInSSR, pagesToContextIds, compId, {contextId: contextPageId, callbackId, eventArgs}) => {
        if (isInSSR) {return}
        const contextId = pagesToContextIds[contextPageId]
        const message = getNativeTpaEventMessage(contextPageId, callbackId, compId, eventArgs)
        if (wixCodeAppAPI[contextId]) {
            wixCodeAppAPI[contextId].sendMessage(message)
        }
    },
    parseCookie,
    toQueryParams
}
