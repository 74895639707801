'use strict'
const _ = require('lodash')
const coreUtils = require('coreUtils')

module.exports = {
    functionLibrary: {
        getStyleData: (bpMap, stylesMap, defaultSkinName, styleId, skinOverride) => {
            const styleData = stylesMap[styleId]
            const skinName = skinOverride || defaultSkinName
            const getStyleData = currentStyleData => _.defaults({}, currentStyleData, {skin: skinName})
            if (styleData && styleData.type === 'StylesInBreakpoint') {
                return styleData.stylesInBreakpoints.map(currentStyle => {
                    const {min, max} = bpMap[currentStyle.breakpoint.replace('#', '')]
                    const shouldUseMin = _.isNumber(min)
                    return {
                        mediaQuery: `@media ${shouldUseMin ? `(min-width: ${min}px)` : ''}${_.isNumber(max) ? `${shouldUseMin ? ' and' : ''} (max-width: ${max}px)` : ''}`,
                        styleData: getStyleData(currentStyle)
                    }
                })
            }

            return getStyleData(styleData)
        },
        shortenStyleId: coreUtils.santaTypes.shortenStyleId
    }
}
