import {linkRenderer} from 'coreUtils' //eslint-disable-line import/no-unresolved
import {wixUrlParser} from 'warmupUtils' //eslint-disable-line import/no-unresolved
import {siteConstants} from 'santa-core-utils'
import {resolveLanguageUrl} from '../multilingualModel/multilingualUrlResolver'

import _ from 'lodash'

export const name = 'NavigationAspect'

export const defaultModel = {
    customUrlMapping: {}
}

const parsePageItemAdditionalData = str => str
    .split(' ')
    .map(keyValue => keyValue.split(':'))
    .reduce((acc, [key, value]) => ({...acc, [key]: value}), {})

export const functionLibrary = {
    parsePageItemAdditionalData,
    renderLink: (linkRenderInfo, linkData, rootNavigationInfo) => linkRenderer.renderLink(linkData, linkRenderInfo, rootNavigationInfo),
    handleHttps: str => str.replace(/^https:/, 'http:'),
    renderImageZoomLink: (linkRenderInfo, rootNavigationInfo, imageData, dataId, propertyQuery, compId) => linkRenderer.renderImageZoomLink(linkRenderInfo, rootNavigationInfo, imageData, dataId, propertyQuery, compId),
    navigateToLanguage: (rawUrl, domain, isPremiumDomain, languageCode, resolutionMethod, originalLanguageCode) => {
        const url = resolveLanguageUrl(domain, languageCode, resolutionMethod, originalLanguageCode, rawUrl, isPremiumDomain)
        if (!!url && url !== rawUrl.full) {
            location.assign(url)
        }
    },
    navigateTo: (startNavigationProcess, renderInfo, focusedRootId, modelData, isInSSR, renderedLink) => {
        const {
            href,
            target,
            pageItemAdditionalData,
            anchorData,
            noUrlChangeAttr,
            isPopup,
            shouldDisableScrollToTop
            // isChangingUrl,
            // isKeepingRoots
        } = renderedLink

        if (isInSSR) {
            return
        }

        const url = noUrlChangeAttr || href
        const navInfo = url ? wixUrlParser.parseUrl(renderInfo, url) : renderedLink
        const pageDataItem = _.get(modelData, ['document_data', navInfo.pageId])
        if (!pageDataItem && navInfo.pageId) { //no pageId when navigating to a dynamic page
            return
        }

        navInfo.isPopup = isPopup || _.get(pageDataItem, 'isPopup')
        navInfo.anchorData = anchorData
        navInfo.shouldDisableScrollToTop = shouldDisableScrollToTop
        navInfo.navigationTime = Date.now()
        navInfo.pageItemAdditionalData = pageItemAdditionalData

        const isPopupGoingToOpen = navInfo.isPopup
        const focusedPageDataItem = _.get(modelData, ['document_data', focusedRootId])
        const isPopupGoingToCloseNotByNavigation = _.get(focusedPageDataItem, 'isPopup') && !isPopupGoingToOpen && target !== '_self'

        const isImageZoomGoingToOpen = navInfo.imageZoom
        const isImageZoomGoingToCloseNotByNavigation = renderInfo.rootNavigationInfo.imageZoom && !isImageZoomGoingToOpen && target !== '_self'

        if (navInfo.anchorData && navInfo.pageId === renderInfo.rootNavigationInfo.pageId &&
            renderInfo.rootNavigationInfo.tpaInnerRoute && !navInfo.tpaInnerRoute) {
            navInfo.tpaInnerRoute = renderInfo.rootNavigationInfo.tpaInnerRoute
        }

        if (!navInfo.anchorData && !isImageZoomGoingToOpen && !isPopupGoingToOpen) {
            navInfo.anchorData = siteConstants.PAGE_ANCHORS.TOP_ANCHOR_ID
        }

        if (isImageZoomGoingToCloseNotByNavigation || isPopupGoingToCloseNotByNavigation) {
            navInfo.anchorData = null
        }

        startNavigationProcess(navInfo)
    },
    isExternalUrl: (renderInfo, url) => !wixUrlParser.parseUrl(renderInfo, url),
    isPermaLinkByRootId: (permaLinksItems, id) => {
        if (!id) {
            return false
        }
        return !!_.find(permaLinksItems, {id})
    },
    getUrl: (linkRenderInfo, options) => {
        const {pageInfo, forceAddPageInfo, cleanQuery, baseUrl, urlMapping, hasAppSectionParams} = options
        const dismissQueryParams = hasAppSectionParams ? [] : ['appSectionParams']
        return wixUrlParser.getUrl(linkRenderInfo, pageInfo, forceAddPageInfo, cleanQuery, baseUrl, urlMapping, dismissQueryParams)
    },
    parseUrl: (linkRenderInfo, url) => wixUrlParser.parseUrl(linkRenderInfo, url),
    openPopup: (windowObject, url, target, params) => {
        windowObject.open(url, target, params)
    }
}

