import _ from 'lodash'
const {withActions} = require('carmi-host-extensions')
const {Effect} = require('./components/Effect')
import React from 'react'

export const name = 'TPAWidgetNativeAspect'
export const functionLibrary = {
    setReactComponent: withActions((actions, widgetId, obj) => {
        const reactComp = _.get(obj, 'default.component')
        actions.setNativeComponentData(widgetId, {component: reactComp})
    }),
    resolveControllersPropsEvents: withActions(({setControllerProps, setPendingControllersPropsUpdates, $runInBatch}, getCurrentProps, propsUpdateEvents) => {
        const currentProps = getCurrentProps()
        $runInBatch(() => {
            _.forOwn(propsUpdateEvents, (propsUpdates, controllerId) => {
                const resolvedProps = _.reduce(propsUpdates, (acc, {events, wixCodeProps}) => ({
                    events: events ? _.defaults(events, acc.events) : acc.events || {},
                    wixCodeProps: wixCodeProps ? _.defaults(wixCodeProps, acc.wixCodeProps) : acc.wixCodeProps || {}
                }), currentProps[controllerId] || {})
    
                setControllerProps(controllerId, resolvedProps)
            })

            setPendingControllersPropsUpdates(null)
        })
    }),
    getEditorIndex: colorName => Number(colorName.split('_').pop()),
    capitalize: v => _.capitalize(v),
    boltComponents: withActions((actions, compId) => ({
        Effect: withHostContext(Effect, actions.addRuntimeCompAnimationStub, compId)
    }))
}

function withHostContext(Component, addRuntimeCompAnimationStub, compId) {
    function WithHostContext(props) {
        return <Component compId={compId} addRuntimeCompAnimationStub={addRuntimeCompAnimationStub} {...props} />
    }

    WithHostContext.displayName = `withHostContext(${Component.displayName || Component.name}`
    return WithHostContext
}

export const defaultModel = {
    data: {},
    props: {}
}

