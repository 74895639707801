import {withActions} from 'carmi-host-extensions/src/aspects/withActions'
import {guidUtils} from 'santa-core-utils'
import {COMP_TYPES} from '../constants'
import $ from 'zepto'

const MODAL_PRFIX = 'tpaModal-'

function getModalStructure() {
    return {
        componentType: COMP_TYPES.TPA_MODAL,
        type: 'Component',
        id: guidUtils.getUniqueId(MODAL_PRFIX),
        skin: 'wysiwyg.viewer.skins.TPAModalSkin'
    }
}

export const modalFunctionLibrary = {
    showModal: withActions((aspectActions, {
        isMobileView,
        exitFullScreenModeFn,
        enterFullScreenModeFn,
        setSiteRootHiddenStateFn,
        setSiteRootAriaHiddenStateFn,
        siteBackgroundComp,
        currentOpenedModalCompId
    }, data = {}, callback) => {
        if (isMobileView) {
            if (data.theme === 'LIGHT_BOX') {
                exitFullScreenModeFn()
                setSiteRootHiddenStateFn(true)
            } else {
                enterFullScreenModeFn({scrollable: false})
                setSiteRootHiddenStateFn(false)
            }
        }
        setSiteRootAriaHiddenStateFn(true)
        siteBackgroundComp.setAriaHiddenState(true)

        const modalStructure = getModalStructure()
        if (currentOpenedModalCompId) {
            aspectActions.setModal(currentOpenedModalCompId, undefined)
        }
        aspectActions.setModal(modalStructure.id, {modalStructure, modalData: data, modalOnClose: callback})
    }),
    getModalCompData: withActions((aspectActions, currentModal) => currentModal),
    removeModal: withActions((aspectActions, {
        isMobileView,
        exitFullScreenModeFn,
        setSiteRootHiddenStateFn,
        setSiteRootAriaHiddenStateFn,
        siteBackgroundComp
    }, modalComp) => {
        if (isMobileView) {
            exitFullScreenModeFn()
            setSiteRootHiddenStateFn(false)
        }
        setSiteRootAriaHiddenStateFn(false)
        siteBackgroundComp.setAriaHiddenState(false)
        aspectActions.setModal(modalComp.props.id, undefined)
    }),
    openModalHandler: withActions((aspectActions, showModalFn, modalData) => new Promise(resolve =>
        showModalFn(modalData, resolve)
    )),
    getWindowSize: windowObject => {
        const zeptoWindowObject = windowObject && $(windowObject)
        if (zeptoWindowObject && zeptoWindowObject.width && zeptoWindowObject.height) {
            return {
                width: zeptoWindowObject.width(),
                height: zeptoWindowObject.height()
            }
        }

        return {}
    }
}
