import _ from 'lodash'

function getDynamicPageLink(id, {routers}) {
    const match = id.match(/^#?([^/]+)\/(.*)$/)
    if (match) {
        const [, prefix, innerRoute] = match
        const routerId = _.findKey(routers, {prefix})
        if (routerId) {
            return {
                type: 'DynamicPageLink',
                routerId,
                innerRoute
            }
        }
    }
    return null
}

function translateSEOIdToPageId(pageId, {mainPageId, pagesDataByPageUriSEO}) {
    if (pageId === '#') {
        return `#${mainPageId}`
    }
    const pageIdWithoutHash = pageId.replace(/^#/, '')
    const {id} = pagesDataByPageUriSEO[pageIdWithoutHash] || {id: pageId}
    return _.startsWith(id, '#') ? id : `#${id}`
}

function fixLink(link, resolverGeneralInfo) {
    if (_.isString(link.pageId) && (link.type === 'PageLink' || link.type === 'AnchorLink')) {
        const dynamicLink = getDynamicPageLink(link.pageId, resolverGeneralInfo)
        return dynamicLink ? {
            ..._.omit(link, 'pageId'),
            ...dynamicLink
        } : {
            ...link,
            pageId: translateSEOIdToPageId(link.pageId, resolverGeneralInfo)
        }
    }
    return link
}

function getFixedGridData(data, compProps, resolverGeneralInfo) {
    if (!compProps) {
        return data
    }
    const linkKeys = _(compProps.columns)
        .map('linkPath')
        .compact()
        .map(linkPath => `${linkPath}_linkObj`)
        .value()

    const isCellLink = key => _.includes(linkKeys, key)

    return _.assign({}, data, {
        rows: _.map(data.rows, row => _.mapValues(row, (val, key) => isCellLink(key) && val ? fixLink(val, resolverGeneralInfo) : val))
    })
}

function getFixedDataRecursively(data, resolverGeneralInfo) {
    return _[_.isArray(data) ? 'map' : 'mapValues'](data, (value, key) => {
        if (key === 'link' && value) {
            return fixLink(value, resolverGeneralInfo)
        }
        if (key === 'linkList') {
            return _.map(value, link => fixLink(link, resolverGeneralInfo))
        }
        if (_.isObject(value)) {
            return getFixedDataRecursively(value, resolverGeneralInfo)
        }
        return value
    })
}

export const resolve = (data, resolverGeneralInfo, {compProps}) => {
    if (data.type === 'Grid') {
        return getFixedGridData(data, compProps, resolverGeneralInfo)
    }

    if (data.type === 'PageLink' || data.type === 'AnchorLink') {
        return fixLink(data, resolverGeneralInfo)
    }
    
    return getFixedDataRecursively(data, resolverGeneralInfo)
}
