import _ from 'lodash'
import events from './events.json'
import {withActions} from 'carmi-host-extensions/src/aspects/withActions'
import siteMembersApis from './runtime/siteMembersApis'
import dialogProps from './runtime/dialogProps'
export const name = 'SiteMembersAspect'

export const defaultModel = {
    loaded: false,
    isSiteMembersDialogsOpenAllowed: true,
    siteMember: {details: null},
    approvedPasswordPages: {},
    pagesJsonFileName: {},
    logoutState: false,
    memberDetailsChangeRegisteredComps: {},
    memberLoginCallbacks: {},
    options: null,
    dialogOptions: null,
    redirectRestricted: null
}

export const functionLibrary = {
    withBI: ({reportSmBi, event, data}, cb = _.noop) => {
        reportSmBi(event, data)
        cb()
    },
    setDialogOnNextRender: withActions(({setDialogOptions}, {reportSmBi, dialogType, navigationInfo}) => {
        if (dialogType) {
            const dialogOptions = navigationInfo ? {dialogType, nextNavigationInfo: navigationInfo, nextPageId: navigationInfo.pageId} : {dialogType}
            setDialogOptions(dialogOptions)
            reportSmBi('SITE_MEMBER_AUTH_DIALOG_OPENED')
        }
    }),
    setInitialState: withActions(({setLoaded, setDialogOptions}, {reportSmBi, loaded, initialDialog, currentUrlPageId, navigationInfo}) => {
        if (!loaded && initialDialog) {
            setDialogOptions({dialogType: initialDialog, nextPageId: currentUrlPageId, initial: true, nextNavigationInfo: navigationInfo})
            reportSmBi('SITE_MEMBER_AUTH_DIALOG_OPENED')
        }
        setLoaded(true)
    }),
    reportSiteMembersBi: ({reportBI, biVisitorId}, biEventKey, params) => {
        const event = events[biEventKey]
        if (event && biVisitorId) { // 'visitorId' is empty in preview mode
            reportBI(event, _.merge(params || {}, {visitor_id: biVisitorId}))
        }
    },
    customNavigation: withActions(({switchDialogType}, params, {navigateToPage, removePopup}) => {
        if (params) {
            removePopup()
            navigateToPage(params)
            switchDialogType(null)
        }
    }),
    hasSocialAppInstalled: configMap => _.some(_.map(configMap, x => JSON.parse(x.config)), x => x.type === 'public' && x.patterns && Object.keys(x.patterns).length > 0),
    getLangFromCookie: smCookie => {
        const parts = smCookie ? smCookie.split('|') : []
        const smCookieLang = parts[1] || parts[0]
        return smCookieLang && smCookieLang.trim() // eslsint-disable-line no-mixed-operators
    },
    ...siteMembersApis,
    ...dialogProps
}

export function init() {
}
